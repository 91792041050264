<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="1200"
    >

        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ options.title ? $t(options.title) : '' }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                class="wrapperFormModal"
            >
                <v-tabs
                    v-model="tab"
                    grow
                    background-color="#e3e3ee"
                    :class="[tabs.length > 1 ? 'tab-selector-panel' : 'd-none']"
                    slider-size="3"
                    mobile-breakpoint="0"
                    @change="handleTabChange"
                >

                    <v-tabs-slider color="#E43958"></v-tabs-slider>

                    <v-tab
                        v-show="tabs.length > 1"
                        v-for="(item, i) in tabs"
                        :key="i"
                    >
                        <v-icon left x-small>
                            {{ item.icon }}
                        </v-icon>
                        {{ $t(item.name) }}
                    </v-tab>

                </v-tabs>

                <v-tabs-items
                    v-model="tab"
                    class="tab-content-panel"
                >

                    <v-tab-item v-for="(tabItem, i) in tabs" :key="i">

                        <v-card flat v-if="tabItem.type === 'Executers'">

                            <v-card-text
                                v-if="loadingExecuters"
                            >
                                <v-col
                                    class="text-subtitle-1 text-center"
                                    cols="12"
                                >
                                    {{ $t("Загрузка...") }}
                                </v-col>

                                <v-col cols="12">
                                    <v-progress-linear
                                        color="#a94442"
                                        indeterminate
                                        rounded
                                        height="6"
                                    ></v-progress-linear>
                                </v-col>

                            </v-card-text>
                            <v-card-text v-else>
                                
                                <div class="form-box-title title-with-right-part">
                                    <span>
                                        {{ $t("Доступно") }}
                                    </span>
                                    <div class="twrp-content">
                                        <v-text-field
                                            :value="search"
                                            @input="onSearchInput($event)"
                                            :placeholder="$t('Поиск')"
                                            hide-details
                                            dense
                                            clearable
                                            required
                                            @click:clear="search=''"
                                            class="twrp-search"
                                            append-icon="fas fa-search"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>
                                    
                                <VirtualScrollTree
                                    :expand-all="false"
                                    :allow-parent-selection="false"
                                    :items="executerTabTreeNodes"
                                    :selected-ids="selectedItemsIds"
                                    :inactive-ids="excludedItemsIds"
                                    @on-select="treeOnSelect"
                                    :filter-string="search"
                                    :items-type="'Executers'"
                                />

                            </v-card-text>

                        </v-card>

                        <v-card flat v-if="tabItem.type === 'Correspondents'">
                            <v-card-text
                                v-if="loadingCorrespondents"
                            >
                                <v-col
                                    class="text-subtitle-1 text-center"
                                    cols="12"
                                >
                                    {{ $t("Загрузка...") }}
                                </v-col>

                                <v-col cols="12">
                                    <v-progress-linear
                                        color="#a94442"
                                        indeterminate
                                        rounded
                                        height="6"
                                    ></v-progress-linear>
                                </v-col>

                            </v-card-text>
                            <v-card-text v-else>

                                <div class="form-box-title title-with-right-part">
                                    <span>
                                        {{ $t("Доступно") }}
                                    </span>
                                    <div class="twrp-content">
                                        <v-text-field
                                            :value="search"
                                            @input="onSearchInput($event)"
                                            :placeholder="$t('Поиск')"
                                            hide-details
                                            dense
                                            clearable
                                            required
                                            @click:clear="search=''"
                                            class="twrp-search"
                                            append-icon="fas fa-search"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>

                                <VirtualScrollTree
                                    :expand-all="false"
                                    :allow-parent-selection="false"
                                    :items="correspondentsTabTreeNodes"
                                    :selected-ids="selectedItemsIds"
                                    :inactive-ids="excludedItemsIds"
                                    @on-select="treeOnSelect"
                                    :filter-string="search"
                                    :items-type="'Correspondents'"
                                />

                            </v-card-text>
                        </v-card>

                        <v-card flat v-if="tabItem.type === 'ExecuterGroups'">
                            <v-card-text
                                v-if="loadingExecuterGroups"
                            >
                                <v-col
                                    class="text-subtitle-1 text-center"
                                    cols="12"
                                >
                                    {{ $t("Загрузка...") }}
                                </v-col>

                                <v-col cols="12">
                                    <v-progress-linear
                                        color="#a94442"
                                        indeterminate
                                        rounded
                                        height="6"
                                    ></v-progress-linear>
                                </v-col>

                            </v-card-text>
                            <v-card-text v-else>
                                
                                <div class="form-box-title title-with-right-part">
                                    <span>
                                        {{ $t("Доступно") }}
                                    </span>
                                    <div class="twrp-content">
                                        <v-text-field
                                            :value="search"
                                            @input="onSearchInput($event)"
                                            :placeholder="$t('Поиск')"
                                            hide-details
                                            dense
                                            clearable
                                            required
                                            @click:clear="search=''"
                                            class="twrp-search"
                                            append-icon="fas fa-search"
                                        >
                                        </v-text-field>
                                    </div>
                                </div>

                                <VirtualScrollTree
                                    :expand-all="false"
                                    :allow-parent-selection="true"
                                    :items="executerGroupsTabTreeNodes"
                                    :selected-ids="selectedItemsIds"
                                    :inactive-ids="excludedItemsIds"
                                    @on-select="treeOnSelect"
                                    :filter-string="search"
                                    :items-type="'ExecuterGroups'"
                                />

                            </v-card-text>
                        </v-card>

                    </v-tab-item>

                </v-tabs-items>

                <!--Блок "Выбрано"-->
                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title title-with-right-part">
                            <span>
                                {{ $t("Выбрано") }}
                            </span>
                            <div class="twrp-content">

                                <div 
                                    v-if="selectedItemsIds.length && !isAllExcluded"
                                    v-tooltip.top-center='$t("Удалить_все")'
                                    @click.stop="onRemoveAllSelected"
                                >
                                    <v-icon role="button" small left>
                                        fas fa-trash
                                    </v-icon>
                                </div>

                            </div>
                        </div>
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12">

                                <div
                                    v-if="selectedItems.length"
                                    class="onlyReadData more-per-lab-wrap three-per-lab-wrap"
                                >
                                    <template v-for="(item, i) in selectedItems">
                                        <v-workplace-chip v-if="item.type == 1 && item.workplaceId"
                                            :id="item.workplaceId"
                                            :name="item.name"
                                            :key="i"
                                            :canDelete="!excludedItemsIds.includes(item.workplaceId)"
                                            :delFunc="onRemoveSelectedByWorkplaceId"
                                        />
                                        <v-enterprise-chip v-if="item.type == 0"
                                            :id="item.enterprise"
                                            :name="item.name"
                                            :key="i"
                                            :canDelete="!excludedItemsIds.includes(item.enterprise)"
                                            :delFunc="onRemoveSelectedByEnterpriseId"
                                        />
                                        <v-enterprise-chip v-if="item.type == 1 && !item.workplaceId"
                                            :id="item.enterprise"
                                            :name="item.name"
                                            :key="i"
                                            :canDelete="!excludedItemsIds.includes(item.enterprise)"
                                            :delFunc="onRemoveSelectedByEnterpriseId"
                                            icon="fas fa-user"
                                        />
                                    </template>
                                </div>
                                <div
                                    v-else
                                    class="cfl-placeholder"
                                >
                                    {{ $t("Нет_выбранных_элементов") }}
                                </div>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <div class="marg-b-10"></div>

                <v-row no-gutters v-if="false">
                    <v-col cols="12" sm="12" md="12">
                        <div class="onlyReadData">
                            <v-checkbox
                                v-model="ignoreCache"
                                :label="$t('Не_использовать_хранилище_оперативного_доступа')"
                                color="primary"
                                hide-details
                                dense
                            ></v-checkbox>
                        </div>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                    color="cyan"
                    text
                    depressed
                    v-if="isOkButtonVisible"
                    @click="ok"
                >
                    {{ $t("Выбрать") }}
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VirtualScrollTree from '../trees/VirtualScrollTree.vue';
import _ from 'lodash';

export default {
    name: "SelectMembersNewDlg",
    components: {
        VirtualScrollTree
    },
    data () {
        return {

        }
    },
    computed: {
        ...mapGetters('dialogs/selectMembersNew',
        {
            visible: 'isVisible',
            loadingExecuters: 'isLoadingExecuters',
            loadingCorrespondents: 'isLoadingCorrespondents',
            loadingExecuterGroups: 'isLoadingExecuterGroups',
            options: 'getOptions',
            tabs: 'getTabs',
            executerTabTreeNodes: 'getExecuterTabTreeNodes',
            correspondentsTabTreeNodes: 'getCorrespondentsTabTreeNodes',
            executerGroupsTabTreeNodes: 'getExecuterGroupsTabTreeNodes',
            selectedItems: 'getSelectedItems',
            selectedItemsIds: 'getSelectedItemsIds',
            excludedItemsIds: 'getExcludedItemsIds'
        }),
        tab: {
            get: function() {
                return this.$store.getters['dialogs/selectMembersNew/getTab'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectMembersNew/SET_TAB', v);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['dialogs/selectMembersNew/getSearch'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectMembersNew/SET_SEARCH', v);
            }
        },
        ignoreCache: {
            get: function() {
                return this.$store.getters['dialogs/selectMembersNew/getIgnoreCache'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectMembersNew/SET_IGNORE_CACHE', v);
            }
        },
        isOkButtonVisible() {
            return this.options.disableEmptySelect === true
                ? this.selectedItems?.length > 0
                : true;
        },
        isAllExcluded() {
            return this.selectedItemsIds.every(sel => this.excludedItemsIds.includes(sel));
        }
    },
    methods: {
        ...mapActions('dialogs/selectMembersNew', ['ok', 'cancel', 'handleTabChange']),
        treeOnSelect(event) {
            let selected = Array.from(this.selectedItems);

            if (event.select) {
                let newItem = this.createSelectedFromEvent(event.value);

                if (newItem) {

                    if (Array.isArray(newItem))
                        selected.push(...newItem);
                    else
                        selected.push(newItem);

                    this.$store.commit('dialogs/selectMembersNew/SET_SELECTED_ITEMS', selected);
                }
            }
            else {
                switch (event.value.element_category)
                {
                    case 'InnerExecuter':
                    case 'OuterExecuter':
                        this.onRemoveSelectedByWorkplaceId(event.value.id);
                        break;

                    case 'OuterESEDO':
                    case 'OuterContractors':
                    case 'OuterPersons':
                        this.onRemoveSelectedByEnterpriseId(event.value.id);
                        break;

                    case 'ExecutersGroup':
                        this.onRemoveMultiple(event.value.children.map(x => x.id));
                        break;
                    
                    default:
                        this.$notify.alert("Неизвестная_категория_элемента_выборки");
                        console.error("Неизвестная_категория_элемента_выборки:", event.value);
                        break;
                }
            }
        },
        createSelectedFromEvent(value) {
            switch (value.element_category)
            {
                case 'InnerExecuter':
                case 'OuterExecuter':
                    return { type: 1, enterprise: value.enterpriseid, workplaceId: value.id, employeeId: value.employeeid, name: value.name, description: "", esedoMember: null, inner: value.element_category === 'InnerExecuter', contacts: value.contacts };

                case 'OuterESEDO':
                    return { type: 0, enterprise: value.id, workplaceId: null, employeeId: null, name: value.name, description: "", esedoMember: true, inner: false, contacts: value.contacts };
                
                case 'OuterContractors':
                    return { type: 0, enterprise: value.id, workplaceId: null, employeeId: null, name: value.name, description: "", esedoMember: false, inner: false, contacts: value.contacts };

                case 'OuterPersons':
                    return { type: 1, enterprise: value.id, workplaceId: null, employeeId: null, name: value.name, description: "", esedoMember: false, inner: false, contacts: value.contacts };

                case 'ExecutersGroup': {
                    let result = [];

                    value.children.forEach(x => {
                        if (x.active == true && !this.selectedItemsIds.includes(x.id)) {
                            result.push(this.createSelectedFromEvent(x));
                        }
                    });

                    return result;
                }

                default:
                    this.$notify.alert("Неизвестная_категория_элемента_выборки");
                    console.error("Неизвестная_категория_элемента_выборки:", event.value);
                    return null;
            }
        },
        onRemoveSelectedByWorkplaceId(id) {
            let tmp = Array.from(this.selectedItems);
            let itemToRemove = tmp.find(x => x.workplaceId === id);
            
            if (itemToRemove) {
                let index = tmp.indexOf(itemToRemove);
                
                if (index > -1) {
                    tmp.splice(index, 1);
                    this.$store.commit('dialogs/selectMembersNew/SET_SELECTED_ITEMS', tmp);
                }
            }
        },
        onRemoveSelectedByEnterpriseId(id) {
            let tmp = Array.from(this.selectedItems);
            let itemToRemove = tmp.find(x => x.enterprise === id);

            if (itemToRemove) {
                let index = tmp.indexOf(itemToRemove);
                
                if (index > -1) {
                    tmp.splice(index, 1);
                    this.$store.commit('dialogs/selectMembersNew/SET_SELECTED_ITEMS', tmp);
                }
            }
        },
        onRemoveMultiple(idsToRemove) {
            let selected = Array.from(this.selectedItems).filter(x => !idsToRemove.includes(x.workplaceId) && !idsToRemove.includes(x.enterprise));
            this.$store.commit('dialogs/selectMembersNew/SET_SELECTED_ITEMS', selected);
        },
        onRemoveAllSelected() {
            let keepSelected = [];

            for (let sel of this.selectedItems) {

                if (sel.type == 1) {
                    if (this.excludedItemsIds.includes(sel.workplaceId)) {
                        keepSelected.push(sel);
                    }
                }

                if (sel.type == 0) {
                    if (this.excludedItemsIds.includes(sel.enterprise)) {
                        keepSelected.push(sel);
                    }
                }
            }

            this.$store.commit('dialogs/selectMembersNew/SET_SELECTED_ITEMS', keepSelected);
        },
        onSearchInput: _.debounce(function (value) {
            this.$store.commit('dialogs/selectMembersNew/SET_SEARCH', value);
        }, 250),
    }
}
</script>